<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Edit Record</h4>
        </div>
      </div>
    </div>
    <!-- {{ getAssessorInformation }} -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row mb-3 mt-2">
                <div class="col-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="" class="h5">Module Name</label>
                    </div>
                    <div class="col-7">
                      <input
                        type="text"
                        class="form-control"
                        v-model="
                          asssessmentTimeline[0].monthly_plan.module.name
                        "
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="" class="h5">Position</label>
                    </div>
                    <div class="col-7">
                      <input
                        type="text"
                        class="form-control"
                        v-model="getpositionLevel"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="row mb-3">
                    <div class="col-3">
                      <label for="" class="h5">Trainer Name</label>
                    </div>
                    <div class="col-7">
                      <v-select
                        label="name"
                        multiple
                        :clearable="false"
                        v-model="trainerVal"
                        disabled
                      >
                      </v-select>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="" class="d-flex h5"
                        ><div class="me-1">Holding</div>
                        <div>Business</div></label
                      >
                    </div>
                    <div class="col-7">
                      <input
                        type="text"
                        class="form-control"
                        v-model="getbusinessUnit"
                        disabled
                      />
                    </div>
                    <div class="col-1"></div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="" class="h5">Assessor Name</label>
                    </div>
                    <div class="col-7">
                      <v-select
                        v-model="assessor.name"
                        label="name"
                        :options="employees"
                        :selectable="
                          (options) => assessor.name !== options.name
                        "
                        :clearable="false"
                        :loading="!employees.length"
                      >
                      </v-select>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="" class="h5">Trainning Date</label>
                    </div>
                    <div class="col-7">
                      <input
                        type="text"
                        class="form-control"
                        v-model="asssessmentTimeline[0].training_date"
                      />
                    </div>
                    <div class="col-1"></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="" class="h5">Department</label>
                    </div>
                    <div class="col-7">
                      <input
                        type="text"
                        class="form-control"
                        v-model="getDepartment"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="" class="h5">Related Hr</label>
                    </div>
                    <div class="col-7">
                      <v-select
                        v-model="related_hr.name"
                        label="name"
                        :options="employees"
                        :selectable="
                          (options) => related_hr.name !== options.name
                        "
                        :clearable="false"
                        :loading="!employees.length"
                      >
                      </v-select>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="row"
                v-for="(data, index) in asssessmentTimeline[0]
                  .assessment_module"
                :key="index"
              >
                <template
                  v-if="data.assessment_questions[0].question_type == 'radio'"
                >
                  <div class="col-9 mt-3">
                    <div class="row mb-2">
                      <div class="h5">
                        {{ data.assessment_questions[0].name }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-3 d-flex">
                        <input
                          type="radio"
                          class="custom-radio border-black"
                          disabled
                        />
                        <div class="ms-2">Strongly Agree</div>
                      </div>
                      <div class="col-3 d-flex">
                        <input type="radio" class="custom-radio" disabled />
                        <div class="ms-2">Agree</div>
                      </div>
                      <div class="col-3 d-flex">
                        <input type="radio" class="custom-radio" disabled />
                        <div class="ms-2">Strongly Disagree</div>
                      </div>
                      <div class="col-3 d-flex">
                        <input type="radio" class="custom-radio" disabled />
                        <div class="ms-2">Disagree</div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div
                class="row"
                v-for="(data, index) in asssessmentTimeline[0]
                  .assessment_module"
                :key="index"
              >
                <template
                  v-if="data.assessment_questions[0].question_type == 'typing'"
                >
                  <div class="col-9 mt-3 mb-2">
                    <p class="h5">
                      {{ data.assessment_questions[0].name }}
                    </p>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="typing"
                      readonly
                    />
                  </div>
                </template>
              </div>

              <div class="col-9 mt-4">
                <p class="h5">
                  If you choose Disagree and Strongly Disagree, please write
                  detailed reason.
                </p>
                <input
                  type="text"
                  class="form-control"
                  placeholder="typing"
                  readonly
                />
              </div>

              <div class="col-9 mt-4">
                <p class="h5">Suggestion for training team.</p>
                <input
                  type="text"
                  class="form-control"
                  placeholder="typing"
                  readonly
                />
              </div>

              <div class="col-9 mt-4">
                <p class="h5">
                  Next improved training titles for your subordinate.
                </p>
                <input
                  type="text"
                  class="form-control"
                  placeholder="typing"
                  readonly
                />
              </div>

              <!-- end row -->
              <div
                class="row"
                v-if="hasPermissions(['ata-assessment-timeline-edit'])"
              >
                <!-- <div class="col-6"></div>       -->
                <div class="col-md-6 mx-auto">
                  <div class="row">
                    <div class="col-2"></div>
                    <div class="col-10">
                      <div class="mt-3 mb-3 ms-3">
                        <router-link :to="{ name: 'assessment-timeline' }">
                          <button
                            type="button"
                            class="btn w-sm btn-secondary me-5"
                          >
                            Cancel
                          </button>
                        </router-link>
                        <button
                          v-if="asssessmentTimeline[0]?.status !== 1"
                          :disabled="loading ? true : false"
                          type="button"
                          class="btn w-sm btn-success waves-effect waves-light px-3"
                          @click="assessmentTimelineUpdate()"
                        >
                          <span
                            v-if="isLoading"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          {{ isLoading == true ? "Loading..." : "Save" }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    vSelect,
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      asssessmentTimeline: [],
      employees: [],
      trainers: [],
      trainerVal: [],
      assessor: {},
      related_hr: {},
      loading: false,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async assessmentTimelineEdit() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/after-assessment-before-week/${this.$route.params.id}`
        )
        .then((res) => {
          if (res.data.data) {
            this.asssessmentTimeline = res.data.data;
            const assessmentTrainers =
              this.asssessmentTimeline[0]?.monthly_plan?.trainers;
            if (assessmentTrainers && assessmentTrainers.length > 0) {
              this.trainerVal = assessmentTrainers.map((trainer) => {
                return {
                  employee_id: trainer.user.employee_id,
                  name: trainer.user.name,
                };
              });
            }
            this.assessor = {
              name: {
                name: this.asssessmentTimeline[0].assessor.name,
                emp_id: this.asssessmentTimeline[0].assessor.employee_id,
              },
            };
            this.related_hr = {
              name: {
                name: this.asssessmentTimeline[0].hr_information?.name,
                emp_id: this.asssessmentTimeline[0].hr_information?.employee_id,
              },
            };
            for (let i in res.data.data[0].monthly_plan.trainers) {
              this.trainers.push({
                name: res.data.data[0].monthly_plan.trainers[i].user?.name,
              });
            }
          }

          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.error = error.response;
          console.log(error);
          this.toast.error("Something went wrong.");
          this.loading = false;
          this.$Progress.finish();
        });
    },
    async assessmentTimelineUpdate() {
      this.isLoading = true;
      const updateData = {
        assessor_id: this.assessor.name.emp_id,
        hr_id: this.related_hr.name.emp_id,
      };
      await axios
        .put(
          `${this.baseUrl}admin/v2/after-assessment-before-week/${this.$route.params.id}`,
          updateData
        )
        .then((res) => {
          this.toast.success("Successfully Assessment Timeline Updated.");
          this.$router.push({ name: "assessment-timeline" });
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.1");
          this.isLoading = false;
        });
    },
  },
  computed: {
    getAssessorInformation() {
      return this.employees.filter(
        (res) => this.assessor.name.name == res.name
      );
    },
    getpositionLevel() {
      if (
        this.getAssessorInformation &&
        this.getAssessorInformation.length > 0
      ) {
        return this.getAssessorInformation[0].position_level?.name;
      }
      return;
    },
    getbusinessUnit() {
      if (
        this.getAssessorInformation &&
        this.getAssessorInformation.length > 0
      ) {
        return this.getAssessorInformation[0].business_unit?.name;
      }
      return;
    },
    getDepartment() {
      if (
        this.getAssessorInformation &&
        this.getAssessorInformation.length > 0
      ) {
        return this.getAssessorInformation[0].department?.name;
      }
      return;
    },
  },
  validations() {
    return {
      module: {
        name: { required },
        duration: { required },
        question_list: [{ type: { required } }],
      },
    };
  },
  created() {
    this.assessmentTimelineEdit();
    let intervalId;
    if (!this.employees.length) {
      intervalId = setInterval(() => {
        this.employees = this.$store.getters["odoo/getAllEmployees"];
        // console.log('+++++running');
        if (this.employees.length) {
          clearInterval(intervalId);
        }
      }, 1000);
    }
  },
};
</script>

<style>
.del {
  color: rgb(126, 26, 26);
}
.del::hover {
  cursor: pointer;
}
.custom-radio {
  /* Increase the size of the radio button */
  transform: scale(1.5);
}
/* .custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.custom-v-select .vs__dropdown-toggle .vs__open-indicator::before {
  display: none;
} */
</style>
